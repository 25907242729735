$light: #e8e8e8;
$linearGradient: linear-gradient(180deg, $primary-base, $primary-dark);
// $primaryRGB: 14, 45, 63;

body {
  font-family: 'AppFonts';
  background: $linearGradient;
}

.overlayer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #ebedef;
}

.overlayed:hover .overlayer {
  opacity: 1;
}

.overlayed {
  position: relative;
}

.xl-text {
  font-size: 8vw;
}

.lg-text {
  font-size: 6vw;
}

.md-text {
  font-size: 4vw;
}

.serviceCardButton {
  visibility: hidden;
}

.serviceCardIcon {
  width: 80%;
}

.serviceCard:hover {
  border: 1.5px solid $secondary-base;
}

.serviceCard:hover .serviceCardButton {
  visibility: visible;
}

.serviceCard:hover .serviceCardIcon {
  width: 65%;
}

.order-lg-1 {
  order: 1;
}

.order-lg-2 {
  order: 2;
}

.order-lg-3 {
  order: 3;
}

.order-lg-4 {
  order: 4;
}

.order-lg-5 {
  order: 5;
}

.order-lg-6 {
  order: 6;
}

@media screen and (max-width: 768px) {
  .xl-text {
    font-size: 12vw;
  }

  .lg-text {
    font-size: 8vw;
  }

  .md-text {
    font-size: 6vw;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }
}

a {
  color: $primary-base;
}

.text-justify {
  text-align: justify;
}

.underlined {
  text-decoration: underline;
}

a:hover {
  color: $secondary-base;
}

.bg-linearGradient {
  background: $linearGradient;
  color: white;
}

input[type='radio']:checked,
input[type='checkbox']:checked {
  background-color: $primary-base;
  border: 1px solid $primary-base;
}

@font-face {
  font-family: 'DroidArabicKufi';
  src: url('../assets/fonts/DroidArabicKufi/Droid-Arabic-Kufi.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DroidArabicKufi';
  src: local('Calibri');
  unicode-range: U+30-39;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AppFonts';
  src: url('../assets/fonts/ArabicFont/Bahij_TheSansArabic-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
  unicode-range: 0600–06FF;
}

@font-face {
  font-family: 'AppFonts';
  src: url('../assets/fonts/EnglishFont/Helvetica-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+0025-00FF;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.tableRow:hover {
  color: black;
  border-top: 0.5vh;
  border-bottom: 0.5vh;
  border-style: solid;
  border-color: $primary-base;
  background-color: rgba(14, 45, 63, 0.1);
}

.tableRow {
  border: 0.5px solid lightgray;
}

.tableHeader {
  border-bottom: 0.25vh;
  border-style: solid;
  border-color: $primary-base;
}

.tableCell {
  border: 0.5px solid lightgray;
}

.highlightCell {
  background-color: rgba(14, 45, 63, 0.1);
}

.veryHighlightCell {
  font-size: large;
  text-align: center;
  background-color: rgba(14, 45, 63, 0.1);
}

.search {
  background-color: rgba(14, 45, 63, 0.25);
  border-color: rgba(0, 0, 0, 0);
  color: white;
}

.searchButton {
  display: none;
}

.search::placeholder {
  color: white;
}

.search:hover,
.search:focus {
  background-color: rgba(14, 45, 63, 0.75);
  color: white;
}

.search:hover::placeholder {
  color: rgba(0, 0, 0, 0);
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  // z-index: 8888;
}

::-webkit-scrollbar-track {
  background: $light;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $secondary-base;
  border: 2pt solid $light;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-base;
}

.linear-loader {
  overflow: hidden;
  width: 100%;
  height: 8px;
  background-color: $primary-base;
  margin: 0;
}

.linear-loading-indicator {
  position: relative;
  width: 100%;
  height: 100%;
}

.linear-loading-indicator:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $secondary-base;
  animation: linear-loading-indicator_first 1.5s infinite ease-out;
}

.linear-loading-indicator:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $primary-base;
  animation: linear-loading-indicator_second 1.5s infinite ease-in;
}

@keyframes linear-loading-indicator_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes linear-loading-indicator_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.tooltipComp {
  position: relative;
  display: inline-block;
  z-index: unset;
}

.tooltipComptext {
  visibility: hidden;
  width: max-content;
  bottom: 100%;
  right: 0%;
  background-color: $primary-base;
  color: #fff;
  text-align: center;
  padding: 1vh 1vw;
  margin-bottom: 1vh;
  border-radius: 6px;
  position: absolute;
  width: max-content;
  // z-index: 9999;
}

.tooltipComp:hover .tooltipComptext {
  visibility: visible;
}
